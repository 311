import getConfig from 'next/config'
import React, { useEffect, useMemo } from 'react'

const {
  publicRuntimeConfig: {
    WEBAPP_URL,
    featureFlags: { showGoogleAuthButton },
  },
} = getConfig()

type GoogleSignInProps = {
  onCredentials: (google_credentials: any, social: 'google' | 'fb') => void
  currentSubdomain: string
}

// Note: this whole flow with the iframe passing Google JWTs around
// was only made necessary because Google Sign In can only load on a page
// with a pre-registered with the origin. Subdomain wildcards are not allowed
// at this point. Hence meeting invitation login pages on the providers' subdomains
// couldn't use Google Sign In unless it were in an iframe with origin app.*
//
// If at some point this doesn't work because of postMessage being brittle or something else,
// checkout the previous commits on the google-auth branch, it was working on non-subdomains
// in a more normal (non-iframe) way.
export const GoogleSignIn = ({ onCredentials, currentSubdomain }: GoogleSignInProps) => {
  const domain = WEBAPP_URL

  const iframeSrc = useMemo(
    () => `${domain}sign-in-with-google-embeddable-page?parentSubdomain=${currentSubdomain}`,
    [domain, currentSubdomain],
  )

  useEffect(() => {
    if (!onCredentials) return

    function onMessage(event: MessageEvent) {
      if (event.origin === domain || event.origin === domain.replace(/\/$/, '')) {
        if (event.data?.google_credentials) {
          onCredentials(event.data.google_credentials, 'google')
        }
      }
    }

    window.removeEventListener('message', onMessage)

    if (window?.addEventListener) {
      window.addEventListener('message', onMessage, false)
    }

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [onCredentials])

  if (!showGoogleAuthButton) {
    return null
  }

  if (!iframeSrc) {
    return null
  }

  return (
    <iframe
      src={iframeSrc}
      frameBorder="0"
      scrolling="no"
      width="250px"
      height="40px"
      style={{ overflow: 'none', margin: '0px auto', borderRadius: '4px' }}
    />
  )
}
