import { Box, BoxExtendedProps, Text } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = BoxExtendedProps & { color: string; message?: string; fontSize?: number }
const ErrorMessage = ({ color, message, fontSize, ...rest }: Props) => {
  const { t } = useTranslation()
  if (!message) {
    return null
  }
  return (
    <Box {...rest} data-cypress="error-message">
      <Text
        color={color}
        textAlign="center"
        size={`${fontSize || 12}px`}
        style={{ whiteSpace: 'break-spaces' }}>
        {t(message)}
      </Text>
    </Box>
  )
}
export default ErrorMessage
