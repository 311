import { Box, DropButton, Menu, Text } from 'grommet'
import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from 'config/api'

import { useMeeting } from 'components/contexts/Meeting'
import { colors } from 'layout/themeColors/colors'
import MeetingsEndpoints from 'services/api/Meetings'

import { AppleLogo } from './icons/AppleLogo'
import BackIcon from './icons/common/BackIcon'
import { GoogleLogo } from './icons/GoogleLogo'
import { OutlookLogo } from './icons/OutlookLogo'

type Props = {
  isStyled: boolean
}

interface CalendarButtonProps {
  labelKey: string
  logo: ReactElement
  onClick: () => void
  style?: React.CSSProperties
}

function CalendarButton({ labelKey, logo, onClick, style }: CalendarButtonProps) {
  const { t } = useTranslation()

  return (
    <Box
      onClick={onClick}
      style={{
        boxShadow: `0px 0px 0px 1px ${colors.primaryBrand} inset`,
        cursor: 'pointer',
        background: colors.white,
        height: '40px',
        ...style,
      }}
      align="center"
      direction="row"
      justify="center">
      <Box justify="end" direction="row" width="80px" gap="8px">
        <Text size="14px" color={colors.dark}>
          {t(labelKey)}
        </Text>
        {logo}
      </Box>
    </Box>
  )
}

const AddToCalendar = ({ isStyled }: Props) => {
  const { t } = useTranslation()
  const { meeting } = useMeeting()

  const [{ data: googleCal }, getGoogleCal] = useApi(
    MeetingsEndpoints.getLinkAddToGoogleCalendar(meeting?.id),
    { manual: true },
  )
  const [{ data: ics }, getIcs] = useApi(MeetingsEndpoints.getIcs(meeting?.id), { manual: true })

  useEffect(() => {
    if (meeting?.id) {
      const getCalendarLinks = async () => {
        await getGoogleCal()
        await getIcs()
      }

      getCalendarLinks().then(() => {})
    }
  }, [meeting?.id])

  if (!meeting) return null

  if (isStyled) {
    return (
      <DropButton
        label={
          <Box direction="row" align="center" gap="8px" justify="center">
            <Text size="14px">{t('website-templates.add-to-your-calendar')}</Text>
            <BackIcon
              color={colors.white}
              width={10}
              height={10}
              style={{ transform: 'rotate(-90deg)' }}
            />
          </Box>
        }
        dropProps={{ margin: { top: '40px' } }}
        style={{
          height: '40px',
          borderRadius: '4px',
          width: '100%',
          marginTop: '10px',
          color: colors.white,
          backgroundColor: colors.primaryBrand,
        }}
        dropContent={
          <Box>
            <CalendarButton
              onClick={() => window.open(googleCal.link, '_blank')}
              labelKey="Google"
              logo={<GoogleLogo width={20} height={20} />}
            />
            <CalendarButton
              onClick={() =>
                window.open(
                  window.URL.createObjectURL(new Blob([ics], { type: 'text/calendar' })),
                  '_blank',
                )
              }
              labelKey="iCal"
              logo={<AppleLogo width={20} height={20} />}
            />
            <CalendarButton
              style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
              onClick={() =>
                window.open(
                  window.URL.createObjectURL(new Blob([ics], { type: 'text/calendar' })),
                  '_blank',
                )
              }
              labelKey="Outlook"
              logo={<OutlookLogo width={20} height={20} />}
            />
          </Box>
        }
      />
    )
  }

  return (
    <Menu
      label={t('website-templates.add-to-your-calendar')}
      alignSelf="center"
      size="small"
      icon={false}
      justifyContent="center"
      items={[
        ...(googleCal?.link
          ? [{ label: 'Google Calendar', onClick: () => window.open(googleCal.link, '_blank') }]
          : []),
        ...(ics
          ? [
              {
                label: 'iCal/Outlook',
                onClick: () =>
                  window.open(
                    window.URL.createObjectURL(new Blob([ics], { type: 'text/calendar' })),
                    '_blank',
                  ),
              },
            ]
          : []),
      ]}
    />
  )
}

export default AddToCalendar
