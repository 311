import _JSXStyle from 'styled-jsx/style'

import { colors } from 'layout/themeColors/colors'

export function SocialLoginsSeparatorStyles() {
  return (
    <_JSXStyle>{`
.c-separator:before,
.c-separator:after {
    background-color: ${colors.lightText};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.c-separator:before {
    right: 1em;
    margin-left: -50%;
}

.c-separator:after {
    left: 1em;
    margin-right: -50%;
}`}</_JSXStyle>
  )
}
