import axios from 'axios'
import { AuthTokenRestrictedNotSetError } from 'cookieStorage'
import { Box, Button } from 'grommet'
import i18next from 'i18next'
import getConfig from 'next/config'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITimezone } from 'react-timezone-select'

import { useApi } from 'config/api'
import { ERROR_CODES, FALLBACK_LANG, LANGUAGES, USER_ROLE } from 'lib/constants'
import { getTimezoneStringFromITimezone } from 'lib/dates'

import AddToCalendar from 'components/AddToCalendar'
import { BorderedButton } from 'components/BorderedButton'
import { useUser } from 'components/contexts/UserProvider'
import ErrorMessage from 'components/ErrorMessage'
import Spinner from 'components/Spinner'
import UserEndpoints from 'services/api/Users'
import {
  loginWithFacebook,
  loginWithGoogleSignIn,
  loginWithPassword,
  loginWithTokens,
} from 'services/auth'

import { FacebookSignIn } from './FacebookSignIn'
import { GoogleSignIn } from './GoogleSignIn'
import { SocialLoginsSeparator } from './SocialLoginsSeparator/SocialLoginsSeparator'

const {
  publicRuntimeConfig: {
    featureFlags: { showGoogleAuthButton, showFacebookAuthButton },
  },
} = getConfig()

type AuthButtonsProps = {
  currentSubdomain: string
  onEmailContinue?: () => void
  hasContinueAsGuest?: boolean
  onGuestContinue?: () => void
  hasAddToCalendar?: boolean
  afterAuth: () => void
  isInvitation?: boolean
  isFromSignupPage?: boolean
  timezone?: ITimezone
  teamId?: string
}

export const AuthButtons = ({
  currentSubdomain,
  onEmailContinue,
  hasContinueAsGuest,
  onGuestContinue,
  hasAddToCalendar,
  afterAuth,
  isInvitation,
  isFromSignupPage,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  teamId,
}: AuthButtonsProps) => {
  const { t } = useTranslation()

  const [user, setUser] = useUser()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const [googleCredentials, setGoogleCredentials] = useState<string | undefined>(undefined)
  const [facebookCredentials, setFacebookCredentials] = useState<string | undefined>(undefined)

  const [signUpCompleted, setSignUpCompleted] = useState(false)

  const languageCode = useMemo(
    () => LANGUAGES.find((lang) => lang.value === i18next.language)?.value || FALLBACK_LANG,
    [i18next.language],
  )

  const [{ data: signUpData, loading: signUpLoading, error: signUpError }, signUpRequest] = useApi(
    UserEndpoints.create(),
    {
      manual: true,
    },
  )

  const signupCommonData = useMemo(() => {
    return {
      user_type_id: isInvitation ? USER_ROLE.CUSTOMER : USER_ROLE.PROVIDER,
      iso_language_code: languageCode,
      timezone: getTimezoneStringFromITimezone(timezone),
      ...(teamId && { team_id: teamId }),
    }
  }, [teamId, isInvitation, languageCode, timezone])

  const sendSignUpRequestToApi = useCallback(
    async (passedValues: any, social?: 'google' | 'fb') => {
      setErrorMessage(undefined)
      if (passedValues?.credentials) {
        await signUpRequest({
          data: {
            ...signupCommonData,
            ...(social === 'fb' ? { facebook_access_token: passedValues?.credentials } : {}),
            ...(social === 'google' ? { google_credentials: passedValues?.credentials } : {}),
          },
        })
      }
    },
    [signupCommonData],
  )

  const sendLoginRequestToApi = useCallback(
    async (passedValues: any, social: 'google' | 'fb') => {
      setErrorMessage(undefined)
      setLoading(true)
      try {
        const user = passedValues?.credentials
          ? social === 'fb'
            ? await loginWithFacebook(passedValues?.credentials)
            : await loginWithGoogleSignIn(passedValues?.credentials)
          : await loginWithPassword(passedValues.email, passedValues.password)
        setUser(user)
        afterAuth()
      } catch (error) {
        console.error(error)
        if (
          axios.isAxiosError(error) &&
          error?.response?.status === ERROR_CODES.NOT_AUTHENTICATED
        ) {
          if (social) {
            await sendSignUpRequestToApi({ credentials: passedValues?.credentials }, social)
          } else {
            setErrorMessage(t('login-form.errors.invalid-credentials'))
          }
        } else {
          if (error instanceof AuthTokenRestrictedNotSetError) {
            setErrorMessage(t('sign-up-form.failed-setting-cookie'))
          } else {
            setErrorMessage(t('errors.generic'))
          }
        }
        setLoading(false) // in order to display the error message
      }
    },
    [afterAuth],
  )

  const onCredentialsLogin = useCallback(
    async (credentials: string, social: 'fb' | 'google') => {
      if (credentials) {
        if (social === 'fb') setFacebookCredentials(credentials)
        if (social === 'google') setGoogleCredentials(credentials)
        await sendLoginRequestToApi({ credentials }, social)
      }
    },
    [sendLoginRequestToApi],
  )

  const onCredentialsSignUp = useCallback(
    async (credentials: string, social: 'fb' | 'google') => {
      if (credentials) {
        if (social === 'fb') setFacebookCredentials(credentials)
        if (social === 'google') setGoogleCredentials(credentials)
        await sendSignUpRequestToApi({ credentials }, social)
      }
    },
    [sendSignUpRequestToApi],
  )

  useEffect(() => {
    if (signUpData) {
      const loginAfterSignup = async () => {
        try {
          setLoading(true)

          let user = null
          if (googleCredentials) {
            user = await loginWithTokens(
              signUpData.jwt_tokens,
              signUpData.user.id,
              'signup',
              'google',
            )
          } else if (facebookCredentials) {
            user = await loginWithTokens(
              signUpData.jwt_tokens,
              signUpData.user.id,
              'signup',
              'facebook',
            )
          } else {
            console.log('NO CREDENTIALS TO LOGIN AFTER SIGNUP')
            return
          }
          setSignUpCompleted(true)
          // Set the user last since the routes depend on it and it causes state update on unmounted components otherwise
          setUser(user)
        } catch (err) {
          if (err instanceof AuthTokenRestrictedNotSetError) {
            setErrorMessage(t('sign-up-form.failed-setting-cookie'))
          }
        }
      }
      loginAfterSignup()
    }
  }, [signUpData, googleCredentials, facebookCredentials])

  useEffect(() => {
    if (signUpError) {
      if (facebookCredentials && signUpError.message === 'error-backend-email-taken') {
        onCredentialsLogin(facebookCredentials, 'fb')
      } else if (googleCredentials && signUpError.message === 'error-backend-email-taken') {
        onCredentialsLogin(googleCredentials, 'google')
      } else {
        setErrorMessage(t(signUpError.message))
      }
      setErrorMessage(t(signUpError.message))
    }
  }, [signUpError])

  useEffect(() => {
    if (user && signUpCompleted && afterAuth) {
      afterAuth()
    }
  }, [user, signUpCompleted, afterAuth])

  if (loading || signUpLoading) {
    return <Spinner />
  }

  return (
    <Box>
      {hasContinueAsGuest && (
        <Button
          primary
          label={t('meeting-invitation.continue-as-guest')}
          size="large"
          onClick={onGuestContinue}
          style={{ width: '250px', margin: '0px auto' }}
        />
      )}
      {hasContinueAsGuest && <SocialLoginsSeparator />}
      {!isFromSignupPage && (
        <Box direction="column" gap="small">
          <Box direction="column" gap="small">
            {showGoogleAuthButton && (
              <GoogleSignIn
                currentSubdomain={currentSubdomain}
                onCredentials={isFromSignupPage ? onCredentialsSignUp : onCredentialsLogin}
              />
            )}
            {showFacebookAuthButton && (
              <FacebookSignIn
                currentSubdomain={currentSubdomain}
                onCredentials={isFromSignupPage ? onCredentialsSignUp : onCredentialsLogin}
              />
            )}
          </Box>
          {onEmailContinue && (
            <BorderedButton
              size="large"
              label={t('continue-with-email')}
              onClick={onEmailContinue}
              style={{ width: '250px', margin: '0px auto' }}
            />
          )}
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {hasAddToCalendar && <AddToCalendar isStyled={false} />}
        </Box>
      )}
    </Box>
  )
}
