import { Box, ResponsiveContext, Text } from 'grommet'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { colors } from 'layout/themeColors/colors'

import { SocialLoginsSeparatorStyles } from './SocialLoginsSeparatorStyles'

export const SocialLoginsSeparator = () => {
  const { t } = useTranslation()
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'
  return (
    <>
      <SocialLoginsSeparatorStyles />
      <Box
        pad={{ vertical: isMobile ? 'large' : 'medium' }}
        style={{ width: '250px', margin: '0px auto' }}>
        <Text
          className="c-separator"
          style={{
            overflow: 'hidden',
            textAlign: 'center',
            color: colors.lightText,
            textTransform: 'uppercase',
          }}>
          {t('or')}
        </Text>
      </Box>
    </>
  )
}
